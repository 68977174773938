import apiService from '@/common/api.service'

export function getAutoclaveList () {
  return apiService({
    url: '/api/autoclave/list',
    method: 'get'
  })
}

export function updateAutoclaveVipStatus (data) {
  return apiService({
    url: '/api/autoclave/vip',
    method: 'patch',
    data
  })
}
export function updateAutoclaveName (data) {
  return apiService({
    url: '/api/autoclave/name',
    method: 'patch',
    data
  })
}
