import apiService from '@/common/api.service'

export function createService (data) {
  return apiService({
    url: '/api/service',
    method: 'post',
    data
  })
}
export function uploadFile (file) {
  const data = new FormData()
  data.append('file', file)
  return apiService({
    url: '/api/service/attachment',
    method: 'post',
    data
  })
}

export function getServiceList (id) {
  return apiService({
    url: '/api/service/list/autoclave',
    method: 'get',
    params: { id }
  })
}
