<template>
  <b-row>
    <b-col cols="4">
      <div
        v-if="autoclaveList.length > 0"
        class="custom-pagination d-flex justify-content-left hasprev mb-4"
        :class="{
          hasnext: selectedAutoclaveIndex !== autoclaveList.length,
          hasprev: selectedAutoclaveIndex !== 1,
        }"
      >
        <b-pagination
          v-model="selectedAutoclaveIndex"
          pills
          hide-goto-end-buttons
          hide-ellipsis
          :total-rows="autoclaveList.length"
          :per-page="1"
          :limit="5"
          @change="onPageChange"
        >
          <template #page>
            <span />
          </template>
          <template #prev-text>
            <font-awesome-icon :icon="['fas', 'caret-left']" fixed-width />
          </template>
          <template #next-text>
            <font-awesome-icon :icon="['fas', 'caret-right']" fixed-width />
          </template>
        </b-pagination>
        <div class="d-flex align-items-center ml-5">
          <row-count :total-rows="autoclaveList.length" :row-pre-page="1" :current-page="selectedAutoclaveIndex" />
        </div>
      </div>
      <div v-if="selectedAutoclave" class="areawrap">
        <template v-if="!edit">
          <div class="d-flex justify-content-between align-items-end mb-2">
            <h3 class="mb-0">
              {{ selectedAutoclave.name }}
            </h3>
            <b-link class="text-gray-500" @click="onEditClick">
              <font-awesome-icon :icon="['fas', 'pen']" fixed-width />
            </b-link>
          </div>
          <h4 class="caption">
            {{ selectedAutoclave.serial_number }}
          </h4>
        </template>
        <div v-else>
          <b-form-group label="Nickname" class="mb-6">
            <b-form-input
              v-model="changeName"
              placeholder="Give your Autoclave a name"
            />
          </b-form-group>
          <div>
            <b-button
              variant="primary"
              class="btn-width"
              @click="onUpdateNameClick"
            >
              SAVE
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-4 btn-width"
              @click="edit = false"
            >
              CANCEL
            </b-button>
          </div>
        </div>
        <hr>
        <b-row>
          <b-col cols="6">
            <b-form-group label="Model" label-class="text-primary mb-1">
              {{ model }}
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group label="Warranty" label-class="text-primary mb-1">
              {{ selectedAutoclave.warranty }}
            </b-form-group>
          </b-col>
          <!-- <b-col cols="6">
            <b-form-group label="Wifi Connection" label-class="text-primary mb-1">
              {{ selectedAutoclave.linked ? 'Connect':'Disconnect' }}
            </b-form-group>
          </b-col> -->
          <b-col cols="6">
            <b-form-group label="Count" label-class="text-primary mb-1">
              {{ emptyString(selectedAutoclave.count) }}
            </b-form-group>
          </b-col>
          <!-- <b-col cols="12">
            <b-form-group label="Product Status" label-class="text-primary mb-1">
              {{ emptyString(selectedAutoclave.status) }}
            </b-form-group>
          </b-col> -->
          <b-col cols="12">
            <b-form-group label="Placement" label-class="text-primary mb-1">
              {{ emptyString(selectedAutoclave.work_station) }}
            </b-form-group>
          </b-col>
        </b-row>
      </div>
    </b-col>
    <b-col cols="8">
      <div class="areawrap">
        <b-row class="align-items-center mb-4">
          <b-col cols="11">
            <b-row class="justify-content-between">
              <b-col class="auto">
                <div class="tabs-base">
                  <b-tabs v-model="tab" content-class="mt-3" @input="onTabChange">
                    <b-tab title="Cycle" active />
                    <b-tab title="Tesings" />
                    <b-tab title="Reminders" />
                    <b-tab title="Service Record" />
                  </b-tabs>
                </div>
              </b-col>
              <b-col cols="3">
                <b-form-datepicker
                  v-if="tab!==3"
                  v-model="searchDate.start"
                  reset-button
                  placeholder="Start Date"
                  class="mb-2"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  locale="en-US"
                />
              </b-col>
              <b-col cols="3">
                <b-form-datepicker
                  v-if="tab!==3"
                  v-model="searchDate.end"
                  reset-button
                  placeholder="End Date"
                  class="mb-2"
                  :date-format-options="{ year: 'numeric', month: '2-digit', day: '2-digit' }"
                  locale="en-US"
                />
              </b-col>
            </b-row>
          </b-col>
          <b-col v-if="tab===0 || tab===1" cols="1" class="ml-auto export-icon">
            <b-button variant="secondary" class="text-gray-500" @click="onDownloadClick()">
              <font-awesome-icon :icon="['fas', 'download']" fixed-width />
            </b-button>
          </b-col>
        </b-row>
        <div class="table-base">
          <b-table
            hover
            borderless
            :items="sortCurrentList"
            :fields="fields"
            :current-page="currentPage"
            :per-page="rowPrePage"
            select-mode="multi"
            @row-clicked="onRowClicked"
            @row-selected="onRowSelected"
          >
            <template #head(selected)>
              <b-form-checkbox
                v-model="selectedAll"
                @change="onSelectedAll"
              />
            </template>
            <template #head(start_date)>
              <div class="cursor-pointer" @click="sortList('start_date')">
                START DATE
                <!-- <font-awesome-icon :icon="['fas', 'sort']" fixed-width /> -->
              </div>
            </template>
            <template #head(applied_time)>
              <div class="cursor-pointer" @click="sortList('applied_time')">
                START TIME
                <!-- <font-awesome-icon :icon="['fas', 'sort']" fixed-width /> -->
              </div>
            </template>
            <template #head(completed_time)>
              <div class="cursor-pointer" @click="sortList('completed_time')">
                END TIME
                <!-- <font-awesome-icon :icon="['fas', 'sort']" fixed-width /> -->
              </div>
            </template>
            <template #head(start_time)>
              <div class="cursor-pointer" @click="sortList('start_time')">
                START TIME
                <!-- <font-awesome-icon :icon="['fas', 'sort']" fixed-width /> -->
              </div>
            </template>
            <template #head(program_name)>
              <div class="cursor-pointer" @click="sortList('program_name')">
                CYCLE
                <!-- <font-awesome-icon :icon="['fas', 'sort']" fixed-width /> -->
              </div>
            </template>
            <template #head(status)>
              <div class="cursor-pointer" @click="sortList('status')">
                STATUS
                <!-- <font-awesome-icon :icon="['fas', 'sort']" fixed-width /> -->
              </div>
            </template>
            <template #head(indicator_result)>
              <div class="cursor-pointer" @click="sortList('indicator_result')">
                INDICATOR
                <!-- <font-awesome-icon :icon="['fas', 'sort']" fixed-width /> -->
              </div>
            </template>
            <template #head(vip)>
              <div class="cursor-pointer" @click="sortList('vip')">
                VIP
                <!-- <font-awesome-icon :icon="['fas', 'sort']" fixed-width /> -->
              </div>
            </template>

            <template #head(date)>
              DATE
            </template>
            <template #head(title)>
              TITLE
            </template>
            <template #head(type)>
              SERVICE
            </template>
            <template #head(download)>
              EXPORT PDF
            </template> <template #head(dispatch_no)>
              NO
            </template>
            <template #cell(selected)="data">
              <b-form-checkbox
                v-model="data.item.selected"
                @change="onRowSelected(data.item)"
              />
            </template>
            <template #cell(start_date)="data">
              {{ $dayjs(data.item.datetime).format('MMM DD, YYYY') }}
            </template>

            <template #cell(start_time)="data">
              {{ $dayjs(data.item.datetime).format("hh:mm A") }}
            </template>  <template #cell(item)="data">
              <span class="allow-detail">
                {{
                  data.item.item
                }}
              </span>
            </template>
            <template #cell(applied_time)="data">
              {{ $dayjs(data.item.applied_time).format('MMM DD, YYYY hh:mm A') }}
            </template>
            <template #cell(completed_time)="data">
              <span v-if="data.item.completed_time">{{ $dayjs(data.item.completed_time).format('MMM DD, YYYY hh:mm A') }}
              </span>
              <span v-else>-</span>
            </template>
            <template #cell(program_name)="data">
              <span :class="allowDetail(data.item.type)===true?'allow-detail':''">
                {{
                  data.item.type === 0
                    ? data.item.program_name
                    : getProgramName(data.item.type)
                }}
              </span>
            </template>
            <template #cell(status)="data">
              <template v-if="tab !== 3">
                <span
                  v-if="data.item.status === 'Complete'"
                  class="
                    d-inline-block
                    bg-success
                    text-white
                    rounded-pill
                    px-2
                    py-0
                  "
                >
                  Completed
                </span>
                <span
                  v-else
                  class="
                    d-inline-block
                    bg-danger
                    text-white
                    rounded-pill
                    px-2
                    py-0
                  "
                >
                  {{ `${data.item.status}` }}
                </span>
              </template>
              <template v-else>
                {{
                  data.item.status === 0 ? "Completed" : `${data.item.status}`
                }}
              </template>
            </template>
            <template #cell(indicator_result)="data">
              <span v-if="data.item.indicators.length>0">
                {{ data.item.indicators.join() }}
              </span>
              <span v-else> - </span>
            </template>
            <template #cell(vip)="data">
              <font-awesome-icon
                :icon="['fas', 'bookmark']"
                :class="data.item.vip ? 'text-warning' : 'text-gray-500'"
                fixed-width
              />
            </template>
            <template #cell(type)="data">
              <span
                v-if="data.item.type === 0"
                class="
                  d-inline-block
                  bg-primary
                  text-white
                  rounded-pill
                  px-2
                  py-0
                "
              >
                維修
              </span>
              <span
                v-else-if="data.item.type === 1"
                class="d-inline-block bg-info text-white rounded-pill px-2 py-0"
              >
                保養
              </span>
              <span
                v-else
                class="
                  d-inline-block
                  bg-warning
                  text-white
                  rounded-pill
                  px-2
                  py-0
                "
              >
                維修
              </span>
            </template>
            <template #cell(download)>
              <b-link class="text-gray-500 btn-icon" href="#">
                <font-awesome-icon
                  :icon="['fas', 'download']"
                  fixed-width
                />
              </b-link>
            </template>
          </b-table>
          <div class="d-flex justify-content-between mt-6">
            <div />
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="rowPrePage"
              align="fill"
              class="my-0"
              last-number
              first-number
            />
            <row-count :total-rows="totalRows" :row-pre-page="rowPrePage" :current-page="currentPage" />
          </div>
        </div>
      </div>
    </b-col>
  </b-row>
</template>
<script>
import modelList from '@/common/modelList'
import cycleTypeList from '@/common/cycleTypeList'
import { getAutoclaveList, updateAutoclaveName } from '@/api/autoclave'
import { getSpecifyist, getExportPackageById } from '@/api/cycle'
import { getServiceList } from '@/api/service'
import RowCount from '@/components/common/rowCount'

export default {
  name: 'ProductInfo',
  components: {
    RowCount
  },
  data () {
    return {
      selectedClinic: 0,
      tab: 0,

      searchDate: {
        start: '',
        end: ''
      },

      selectedAutoclaveIndex: 1,
      autoclaveList: [],

      scheduleList: [],

      edit: false,
      changeName: '',
      serviceList: [],

      currentPage: 1,
      rowPrePage: 10,
      selectedHistories: []
    }
  },
  computed: {
    selectedAll () {
      return this.sortCurrentList.length === this.selectedHistories.length
    },
    selectedAutoclave () {
      return this.autoclaveList[this.selectedAutoclaveIndex - 1]
    },
    model () {
      return modelList.map(p => p.options).flat().find((m) => m.value === this.selectedAutoclave.model)
        ?.text
    },

    fields () {
      if (this.tab === 3) {
        return ['applied_time', 'completed_time', 'dispatch_no', 'title', 'type', 'status', 'download']
      } else if (this.tab === 0 || this.tab === 1) {
        return ['selected', 'start_date', 'start_time', 'program_name', 'status', 'indicator_result', 'vip']
      } else {
        return ['start_date', 'start_time', 'program_name', 'status', 'indicator_result', 'vip']
      }
    },
    sortCurrentList () {
      let list = []
      let typelist = []
      switch (this.tab) {
        case 0:
          typelist = cycleTypeList.find(p => p.label === 'OtherType').options.map(p => p.value)
          list = this.scheduleList.filter(item => typelist.includes(item.type))
          break
        case 1:
          typelist = cycleTypeList.find(p => p.label === 'Testings').options.map(p => p.value)
          list = this.scheduleList.filter(item => typelist.includes(item.type))
          break
        case 2:
          var maintenancelist = cycleTypeList.find(p => p.label === 'Maintenance').options.map(p => p.value)
          typelist = cycleTypeList.find(p => p.label === 'Cleaning').options.map(p => p.value).concat(maintenancelist)
          list = this.scheduleList.filter(item => typelist.includes(item.type))
          break
        case 3:
          list = this.serviceList
          break
      }
      if (this.tab !== 3) {
        if (this.searchDate.start) {
          var startDate = this.$dayjs(this.searchDate.start)
          list = list.filter(p => this.$dayjs(p.datetime).diff(startDate, 'day') >= 0)
        }
        if (this.searchDate.end) {
          var endDate = this.$dayjs(this.searchDate.end)
          list = list.filter(p => this.$dayjs(p.datetime).diff(endDate, 'day') <= 0)
        }
        // const date = this.searchDate ? this.$dayjs(this.searchDate).format('YYYY-MM-DD') : null
        // list = list.filter(a => this.$dayjs(a.datetime).format('YYYY-MM-DD') === date)
      }
      list.forEach(item => { item.selected = false })
      return list.slice().sort((a, b) => {
        const dataA = String(a[this.field]).toLowerCase()
        const dataB = String(b[this.field]).toLowerCase()
        if (dataA > dataB) return this.ascending ? 1 : -1
        if (dataA < dataB) return this.ascending ? -1 : 1
        else return 0
      })
    },
    totalRows () {
      return this.sortCurrentList.length
    }
  },
  created () {
    this.$store.commit('product/CLEAR')
    this.init()
  },
  methods: {
    async init () {
      await this.getAutoclaveList()
      await this.getHistoryList(this.selectedAutoclave.id)
      await this.getServiceList(this.selectedAutoclave.id)
    },
    async getAutoclaveList () {
      return getAutoclaveList().then((res) => {
        this.autoclaveList = res.data.data
      })
    },
    async getHistoryList (id) {
      return getSpecifyist(id).then((res) => {
        this.scheduleList = res.data.data
      })
    },
    async getServiceList (id) {
      return getServiceList(id).then(res => {
        this.serviceList = res.data.data
      })
    },
    getProgramName (type) {
      return cycleTypeList.map(p => p.options).flat().find(m => m.value === type)?.text
    },
    emptyString (val) {
      return !val ? '-' : val
    },
    allowDetail (type) {
      var testinglist = cycleTypeList.find(p => p.label === 'Testings').options.map(p => p.value)
      var typelist = cycleTypeList.find(p => p.label === 'OtherType').options.map(p => p.value).concat(testinglist)
      return typelist.includes(type)
    },
    onEditClick () {
      this.edit = true
      this.changeName = this.selectedAutoclave.name
    },
    onUpdateNameClick () {
      updateAutoclaveName({
        id: this.selectedAutoclave.id,
        name: this.changeName
      }).then((res) => {
        if (res.data.success) {
          this.selectedAutoclave.name = this.changeName
          this.edit = false
        }
      })
    },
    async onPageChange (page) {
      this.edit = false
      const autoclave = this.autoclaveList[page - 1]
      await this.getHistoryList(autoclave.id)
      await this.getServiceList(autoclave.id)
    },
    onTabChange () {
      this.selectedHistories = []
      this.currentPage = 1
    },
    onRowClicked (item) {
      if (this.tab === 0 || this.tab === 1) {
        if (this.allowDetail(item.type)) {
          this.$store.commit(
            'product/SET_SELECTED',
            this.selectedAutoclave,
            item.id
          )
          this.$store.commit('product/SET_SELECTED_HISTORY', item)
          this.$store.commit('product/SET_HISTORY_LIST', this.scheduleList.filter(s => s.type === 0 || s.type === 10 || s.type === 11 || s.type === 12))
          this.$router.push({ name: 'ProductDetail' })
        }
      }
    },
    onRowSelected (item) {
      if (item.selected) { this.selectedHistories.push(item) } else {
        this.selectedHistories = this.selectedHistories.filter(a => a.id !== item.id)
      }
    },
    sortList (field) {
      if (this.field === field) {
        this.ascending = !this.ascending
      } else {
        this.field = field
        this.ascending = true
      }
    },
    onDownloadClick () {
      if (this.selectedHistories.length > 0) {
        const id = this.selectedHistories.map(p => p.id)
        getExportPackageById(id).then((res) => {
          const filename = 'package.zip'
          const fileUrl = URL.createObjectURL(res.data)
          const fileLink = document.createElement('a')

          fileLink.href = fileUrl
          fileLink.download = filename

          fileLink.click()
        })
      }
    },
    onSelectedAll (val) {
      this.sortCurrentList.forEach(item => {
        if (val && item.selected !== val) {
          this.selectedHistories.push(item)
        }
        item.selected = val
      })
      if (!val) this.selectedHistories = []
    }
  }
}
</script>
<style lang="scss" scoped>
@import "~bootstrap/scss/functions";
@import "~@/assets/style/variables";
.caption {
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #545a5d;
}
.btn-import {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: none;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  background: #00a7e0;
  color: #fff;

  .menu-img-active {
    display: none;
  }

  &:hover {
    background: #f0f0f3;
    color: $primary;

    .menu-img {
      display: none;
    }

    .menu-img-active {
      display: inline-block;
    }
  }
}
.allow-detail{
  cursor: pointer;
}
.export-icon{
  button{
    font-size: 16px;
    margin-bottom: 15px;
  }
}
</style>
